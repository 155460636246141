/*******************************************************************************
* Header : This components used for header . In header show the logout funcation,
  bell icon and user profile
* Contributors: Abhishek Kuamr Yadav
*******************************************************************************/
import React from "react";

import { Menu } from "semantic-ui-react";

/* Component is used as a Header in webapp */
const Header = (props) => {

  return (
    <>
      {/* <Sidebar show={show} setShow={setShow} /> */}
      <Menu borderless fixed="top" className="header" id="menu">
        <Menu.Item className="emigration-text">eMigration Advisor</Menu.Item>
      </Menu>
    </>
  );
};

export default Header;
