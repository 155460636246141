import React, { useEffect, useState, useContext } from "react";
import "./../../css/formField.css";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorsContext } from "../Contexts/ErrorsContext";
import axios from "axios";
const Reupload = (props) => {
  const [educationalSheets, setEducationalSheets] = useState([
    { id: 1, sheet: {}, inputsEnabled: [], sheets: [], documentIds: [] },
  ]);
  const [employmentSheets, setEmploymentSheets] = useState([
    { id: 1, sheet: {}, inputsEnabled: [], sheets: [], documentIds: [], evidence: {}, total: 0 },
  ]);
  const [empEvidences, setEmpEvidences] = useState([]);
  const [sheetData, setSheetData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    email_id: "",
    passport: "",
    government_id: "",
    resume: "",
    birth_cert: "",
    name_change_cert: "",
    educational: [],
    employment: [],
    file_ids: [],
    file_names: [],
    rpl_ref_form: "",
    emp_sheet: "",
  });

  useEffect(async () => {

    await axios.get(`https://api.xhorizons-dev.lexxtechnologies.com/getemps/${id}`)
      .then((res) => {
        const evidences = res.data;
        setEmpEvidences(evidences);
      })

    axios.get(`https://api.xhorizons-dev.lexxtechnologies.com/get_errors/${id}`)
      .then((res) => {
        const errors = res.data;
        setErrors(errors);
        if (Object.keys(errors.errors.educational).length > 0) {
          const total = errors.errors.educational.total;

          setEducationalSheets([...Array(total).keys()].map(index => {
            const value = errors.errors.educational[index];

            return {
              id: index + 1,
              sheet: {},
              inputsEnabled: Object.keys(value || {}).map(v => Number(v)),
              sheets: value,
              documentIds: Object.values(value || {}).map(v => v.DocumentID),

            };
          }));
        }


        if (Object.keys(errors.errors.employment).length > 0) {
          const total = errors.errors.employment.total;

          setEmploymentSheets([...Array(total).keys()].map(index => {
            const value = errors.errors.employment[index];

            return {
              id: index + 1,
              sheet: {},
              inputsEnabled: Object.keys(value || {}).map(v => Number(v)),
              sheets: value,
              documentIds: Object.values(value || {}).map(v => v.DocumentID),

            };
          }));
        }


      })
      .catch((err) => {
        console.log(err);
      });


  }, []);

  useEffect(() => {
    console.log(employmentSheets);

  }, [employmentSheets]);


  useEffect(() => {
    console.log(data);
  }, [data]);
  const addMoreEducationSheet = () => {

    const newId = educationalSheets.length + 1;
    setEducationalSheets([...educationalSheets, { id: newId, sheet: {}, inputsEnabled: [] }]);
  };

  const removeEducationSheet = (id) => {
    if (educationalSheets.length === 1) {
      alert("At least one educational document is mandatory.");
      return;
    }
    setEducationalSheets(educationalSheets.filter((sheet) => sheet.id !== id));
  };

  const addMoreEmploymentSheet = () => {
    const newId = employmentSheets.length + 1;
    setEmploymentSheets([...employmentSheets, { id: newId, sheet: {}, inputsEnabled: [] }]);
  };

  const removeEmploymentSheet = (id) => {
    if (employmentSheets.length === 1) {
      alert("At least one employment document is mandatory.");
      return;
    }
    setEmploymentSheets(employmentSheets.filter((sheet) => sheet.id !== id));
  };

  const onchangeElement = (e) => {

    const { name, value, files } = e.target;
    const id = e.target.getAttribute("DocumentID");
    const filePrefix = e.target.getAttribute("filePrefix");
    console.log(filePrefix)
    if (name === "email_id") {
      setData({ ...data, [name]: value });
    } else if (files && files[0]) {
      // Check file size
      if (name !== "emp_sheet" && files[0].size > 1e6) {
        alert(
          "File size is more than 1MB. Please upload a file with size less than 1MB."
        );
        e.target.value = ""; // Clear the file input
        return;
      }
      // Check file type
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
      ];
      if (!allowedTypes.includes(files[0].type)) {
        alert("Please upload only PDF or Word files.");
        e.target.value = ""; // Clear the file input
        return;
      }
      setData({
        ...data, [name]: files[0], file_ids: data.file_ids.includes(id) ? data.file_ids : [...data.file_ids, id], file_names: data.file_ids.includes(id) ? data.file_names.map((name, index) => {
          if (index === data.file_ids.indexOf(id)) {
            return `${filePrefix}_${id}_${e.target.files[0].name}`
          }
          return name
        }) : [...data.file_names, `${filePrefix}_${id}_${e.target.files[0].name}`]
      });
    }
  };

  const onchangeElement1 = (e) => {
    if (!e.target.files || !e.target.files[0]) {
      return; // No file selected, do nothing
    }

    if (e.target.files[0].size > 1e6) {
      alert("File size is more than 1MB. Please upload a file with size less than 1MB.");
      e.target.value = ""; // Clear the file input
      return;
    }

    let name = e.target.name;
    let id = e.target.getAttribute("DocumentID");

    let temp = sheetData;
    const documentNumber = e.target.getAttribute("documentNumber");
    const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      alert("Please upload only PDF or Word files.");
      e.target.value = ""; // Clear the file input

      return;
    }
    const employmentPrefix = "Employment_Document";
    const educationalPrefix = "Educational_Document";
    temp[name] = e.target.files[0];
    const DocumentType = e.target.getAttribute("DocumentType");
    console.log(DocumentType);

    setSheetData({ ...temp });

    // Update the educational array with the new file
    setData({
      ...data,
      educational: DocumentType === "Educational" ? data.file_ids.includes(id) ? data.educational.map((value, index) => {
        if (index === data.file_ids.indexOf(id)) {
          return e.target.files[0]
        }
        return value
      }) : [...data.educational, e.target.files[0]] : [...Object.values(data.educational)],

      employment: DocumentType === "Employment" ? data.file_ids.includes(id) ? data.employment.map((value, index) => {
        if (index === data.file_ids.indexOf(id)) {
          return e.target.files[0]
        }
        return value
      }) : [...data.employment, e.target.files[0]] : [...Object.values(data.employment)],

      file_ids: data.file_ids.includes(id) ? data.file_ids : [...data.file_ids, id],
      file_names: data.file_ids.includes(id) ? data.file_names.map((name, index) => {
        if (index === data.file_ids.indexOf(id)) {
          return `${DocumentType === "Educational" ? educationalPrefix : employmentPrefix}_${documentNumber}_${id}_${e.target.files[0].name}`
        }
        return name
      }) : [...data.file_names, `${DocumentType === "Educational" ? educationalPrefix : employmentPrefix}_${documentNumber}_${id}_${e.target.files[0].name}`]
    });
  };



  useEffect(() => {
    console.log(data);
  }, [data]);

  // const checkMandatoryFiles = (mandatoryFiles) => {
  //   if (mandatoryFiles.includes("educational")) {
  //     console.log(data.educational.length);
  //     if (data.educational.length === Object.values(errors.errors.educational).reduce((acc, value) => {
  //       console.log(acc + Object.keys(value).length);
  //       return acc + Object.keys(value).length;
  //     }, 0)) {
  //       alert(`Please fill all mandatory fields for education `);
  //       return false;
  //     }
  //   }
  //   if (mandatoryFiles.includes("employment")) {
  //     console.log(data.employment.length);
  //     if (data.employment.length !== Object.values(errors.errors.employment).reduce((acc, value) => {
  //       console.log(acc + Object.keys(value).length);
  //       return acc + Object.keys(value).length;
  //     }, 0)) {
  //       alert(`Please fill all mandatory fields. for  employment`);
  //       return false;
  //     }
  //     else {
  //       return true
  //     }
  //   }

  //   for (let file of mandatoryFiles) {
  //     if (!data[file]) {

  //       alert(`Please fill all mandatory fields. for ${file}`);
  //       return false;
  //     }
  //   }

  //   return true;
  // };

  const upload = async () => {
    const mandatoryFiles = [
      "passport",
      "employment"


    ];
    
    const formData = new FormData();
    formData.append("email_id", data.email_id);
    formData.append("passport", data.passport);
    formData.append("government_id", data.government_id);
    formData.append("resume", data.resume);
    formData.append("birth_cert", data.birth_cert);
    formData.append("name_change_cert", data.name_change_cert);




    data.educational.forEach((eduDoc) => {
      formData.append("educational", eduDoc);
    });

    data.employment.forEach((empDoc) => {
      formData.append("employment", empDoc);
    });

    data.file_ids.forEach((file_id) => {
      formData.append("file_ids", file_id);
    });

    data.file_names.forEach((file_name) => {
      formData.append("file_names", file_name);
    });

    if (data.emp_sheet) {
      formData.append("emp_sheet", data.emp_sheet);
    }




    const settings = {
      method: "POST",
      body: formData,
    };

    try {
      formData.forEach((value, key) => {
        console.log(key + " " + value);
      });
      const fetchResponse = await fetch(
        "http://localhost:8000/reupload/EM0000001",
        settings
      );
      const responseJson = await fetchResponse.json();
      if (fetchResponse.ok) {
        if (responseJson.error) {
          alert(responseJson.error); // Error from Python backend
        } else {
          // alert("Files uploaded successfully!");
          //navigate("/formsubmitted");
        }
      } else {
        alert(`Upload failed. HTTP Status: ${fetchResponse.status}`);
      }
    } catch (e) {
      console.log(e);
      alert(
        "An error occurred during upload. Please check the console for details."
      );
    }
  };

  return (
    <>
      <div className="form_body">
        <div className="h-context">
          <h3 className="pt-5 form_heading">Upload Documents</h3>
          <ul>
            <li style={{ color: "red" }}>
              The documents should be clear and colored.
            </li>
            <li style={{ color: "red" }}>
              The photograph in the document should be clearly visible.
            </li>
            <li style={{ color: "red" }}>
              Please note that fields marked with an asterisk (*) are mandatory.
            </li>
            <li style={{ color: "red" }}>
              Please upload files in PDF or .docx format only.
            </li>
          </ul>
        </div>
        <form id="form1" className="pb-10" encType="multipart/form-data">
          <div>
            <h3 className="heading-form personal_details mb-5">
              PERSONAL DETAILS
            </h3>
            <div className="grid-container three-by-two">
              <div className="first-name personal_details_inputfields">
                <label>
                  Email{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input style={{ backgroundColor: "lightgrey" }}
                  disabled
                  value={errors && errors.errors && errors.errors.email ? errors.errors.email : ""}
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="text"
                  name="email_id"
                />
              </div>
              <div className="last-name personal_details_inputfields">
                <label>
                  {" "}
                  Passport{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  disabled={errors && errors.errors && errors.errors.PASSPORT ? false : true}
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="passport"
                  filePrefix="PASSPORT"
                  DocumentID={errors && errors.errors && errors.errors.PASSPORT ? `${errors.errors.PASSPORT.DocumentID}` : null}
                />
                {errors && errors.errors && errors.errors.PASSPORT ?
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                    <div style={{ display: "flex", width: "298px", textAlign: "start" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{errors.errors.PASSPORT.DocumentName}</div></div>
                    <div style={{ display: "flex", width: "298px" }} >Error:<div style={{ color: "red", wordBreak: "break-word", textAlign: "start" }}>{JSON.parse(errors.errors.PASSPORT.ErrorMessage)}</div></div>
                  </div>
                  : null}
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Government ID
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  disabled={errors && errors.errors && errors.errors.GOVERNMENT_ID ? false : true}
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="government_id"
                  filePrefix="GOVERNMENT_ID"
                  DocumentID={errors && errors.errors && errors.errors.GOVERNMENT_ID ? `${errors.errors.GOVERNMENT_ID.DocumentID}` : null}
                />
                {errors && errors.errors && errors.errors.GOVERNMENT_ID ?
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                    <div style={{ display: "flex", width: "298px", textAlign: "start" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{errors.errors.GOVERNMENT_ID.DocumentName}</div></div>
                    <div style={{ display: "flex", width: "298px" }} >Error:<div style={{ color: "red", wordBreak: "break-word", textAlign: "start" }}>{JSON.parse(errors.errors.GOVERNMENT_ID.ErrorMessage)}</div></div>
                  </div>
                  : null}

              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  VEVO issued document or ImmiCard (or birth certificate)
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  disabled={errors && errors.errors && errors.errors.BIRTH_VEVO_IMMICARD ? false : true}
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="birth_cert"
                  filePrefix="BIRTH_VEVO_IMMICARD"
                  DocumentID={errors && errors.errors && errors.errors.BIRTH_VEVO_IMMICARD ? `${errors.errors.BIRTH_VEVO_IMMICARD.DocumentID}` : null}
                />
                {errors && errors.errors && errors.errors.BIRTH_VEVO_IMMICARD ?
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                    <div style={{ display: "flex", width: "298px", textAlign: "start" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{errors.errors.BIRTH_VEVO_IMMICARD.DocumentName}</div></div>
                    <div style={{ display: "flex", width: "298px" }} >Error:<div style={{ color: "red", wordBreak: "break-word", textAlign: "start" }}>{JSON.parse(errors.errors.BIRTH_VEVO_IMMICARD.ErrorMessage)}</div></div>
                  </div>
                  : null}
              </div>
              <div className="personal_details_inputfields">
                <label> Evidence of Name change/ Marriage Certificate</label>
                <input
                  disabled={errors && errors.errors && errors.errors.NAME_CHANGE_CERTIFICATE ? false : true}
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="name_change_cert"
                  DocumentID={errors && errors.errors && errors.errors.NAME_CHANGE_CERTIFICATE ? `${errors.errors.NAME_CHANGE_CERTIFICATE.DocumentID}` : null}
                  filePrefix="NAME_CHANGE_CERTIFICATE"

                />
                {errors && errors.errors && errors.errors.NAME_CHANGE_CERTIFICATE ?
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                    <div style={{ display: "flex", width: "298px", textAlign: "start" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{errors.errors.NAME_CHANGE_CERTIFICATE.DocumentName}</div></div>
                    <div style={{ display: "flex", width: "298px" }} >Error:<div style={{ color: "red", wordBreak: "break-word", textAlign: "start" }}>{JSON.parse(errors.errors.NAME_CHANGE_CERTIFICATE.ErrorMessage)}</div></div>
                  </div>
                  : null}
              </div>
              <div className="personal_details_inputfields">
                <label>
                  Resume{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  disabled={errors && errors.errors && errors.errors.RESUME ? false : true}
                  id="resume"
                  onChange={onchangeElement}
                  accept="application/pdf,.doc,.docx"
                  type="file"
                  name="resume"
                  filePrefix="RESUME"
                  DocumentID={errors && errors.errors && errors.errors.RESUME ? `${errors.errors.RESUME.DocumentID}` : null}
                />
                {errors && errors.errors && errors.errors.RESUME ?
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                    <div style={{ display: "flex", width: "298px", textAlign: "start" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{errors.errors.RESUME.DocumentName}</div></div>
                    <div style={{ display: "flex", width: "298px" }} >Error:<div style={{ color: "red", wordBreak: "break-word", textAlign: "start" }}>{JSON.parse(errors.errors.RESUME.ErrorMessage)}</div></div>
                  </div>
                  : null}
              </div>
            </div>
            <div className="form-filed-area-1"></div>
          </div>

          <div>
            <h3
              style={{ marginBottom: "0px" }}
              className="heading-form personal_details "
            >
              EDUCATIONAL DETAILS
            </h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  paddingTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Relevant Documents
              </div>
              <div
                id="EducationDetail"
                className="filed-area"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "47px",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                {educationalSheets.map((item, index) => {
                  console.log(item);
                  return (
                    <div
                      key={index}
                      className="educational_details_inputfields"
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label style={{ fontSize: "13px" }} >
                          Certificate{" "}
                          <label style={{ color: "red", fontSize: "13px" }}>
                            *
                          </label>
                        </label>
                        <input
                          disabled={!item.inputsEnabled.includes(0)}
                          onChange={onchangeElement1}
                          accept="application/pdf"
                          type="file"
                          name={`educationsheet${item.id}`}
                          DocumentID={item.inputsEnabled.includes(0) ? `${item.sheets["0"].DocumentID}` : null}
                          documentNumber={0}
                          DocumentType="Educational"
                        />
                        {item.inputsEnabled.includes(0) ? <div className="">

                          <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{item.sheets["0"].DocumentName}</div></div>
                          <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >Error:<div style={{ color: "red", wordBreak: "break-word" }}>{JSON.parse(item.sheets["0"].ErrorMessage)}</div></div>
                        </div> : null}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label style={{ fontSize: "13px" }} >
                          Transcript/Marksheet{" "}
                          <label style={{ color: "red", fontSize: "13px" }}>
                            *
                          </label>
                        </label>
                        <input
                          disabled={!item.inputsEnabled.includes(1)}
                          onChange={onchangeElement1}
                          accept="application/pdf"
                          type="file"
                          name={`educationtranscript${item.id}`}
                          DocumentID={item.inputsEnabled.includes(1) ? `${item.sheets["1"].DocumentID}` : null}
                          documentNumber={1}
                          DocumentType="Educational"
                        />
                        {item.inputsEnabled.includes(1) ? <div className="">

                          <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{item.sheets["1"].DocumentName}</div></div>
                          <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >Error:<div style={{ color: "red", wordBreak: "break-word" }}>{JSON.parse(item.sheets["1"].ErrorMessage)}</div></div>
                        </div> : null}
                      </div>

                      <div
                        onClick={() => removeEducationSheet(item.id)}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              onClick={addMoreEducationSheet}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#0971ce",
                width: "60px",
                height: "40px",
                // borderRadius: "10000px",
                marginTop: "30px",
                alignItems: "center",
                margin: "auto",
                color: "white",
                fontWeight: "bolder",
              }}
            >
              Add
            </div>
          </div>
          {/* <div>
            <h3 className="heading-form personal_details mb-5">RPL</h3>
            <div className="filed-area">
              <div style={{width:'35%'}}  className="first-name personal_details_inputfields">
                <label>RPL Reference </label>
                <input
                  className="educational_details_inputfields"
                  onChange={onchangeElement}
                  accept=".doc,.docx"
                  type="file"
                  name="rpl_ref_form"
                />
              </div>
            </div>
          </div> */}
          <div>
            <h3 className="heading-form personal_details mb-5">
              EMPLOYMENT REFERENCE
            </h3>
            <ul>
              <li style={{ color: "red" }}>
                Please upload files in .docx format only
              </li>
            </ul>
            <div className="filed-area">
              <div
                style={{ width: "35%" }}
                className="first-name personal_details_inputfields"
              >
                <label>
                  Employment Sheet{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  disabled={errors && errors.errors && errors.errors.EMP_SHEET ? false : true}
                  className="educational_details_inputfields"
                  onChange={onchangeElement}
                  accept=".doc,.docx"
                  type="file"
                  name="emp_sheet"
                  filePrefix="EMP_SHEET"
                  DocumentID={errors && errors.errors && errors.errors.EMP_SHEET ? `${errors.errors.EMP_SHEET.DocumentID}` : null}
                />
                {errors && errors.errors && errors.errors.EMP_SHEET ?
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                    <div style={{ display: "flex", width: "298px", textAlign: "start" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{errors.errors.EMP_SHEET.DocumentName}</div></div>
                    <div style={{ display: "flex", width: "298px" }} >Error:<div style={{ color: "red", wordBreak: "break-word", textAlign: "start" }}>{JSON.parse(errors.errors.EMP_SHEET.ErrorMessage)}</div></div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
          <div>
            <h3
              style={{ marginBottom: "0px" }}
              className="heading-form personal_details "
            >
              EMPLOYMENT EVIDENCES
            </h3>
            {
              empEvidences.length > 0 ? <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>

                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    paddingTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Relevant Documents
                </div>
                <div
                  id="EducationDetail"
                  className="filed-area"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "47px",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {employmentSheets.map((item, index) => {
                    
                    const emp_ref_index = item.sheets !== undefined && item.sheets.length > 0 ? item.sheets.length - 1 : 0;
                    console.log(item.sheets !== undefined && item.sheets.length > 0 ? item.sheets.length : 0);
                    return (
                      <div style={{display:"grid", gap:"10px"}}>
                        <div>{empEvidences[index].EmploymentData.employment_record}</div>
                        <div
                          key={index}
                          className="educational_details_inputfields"
                        >
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ fontSize: "13px" }} >
                              Employment Reference{" "}
                              <label style={{ color: "red", fontSize: "13px" }}>
                                *
                              </label>
                            </label>
                            <input
                              disabled={!item.inputsEnabled.includes(0)}
                              onChange={onchangeElement1}
                              accept="application/pdf"
                              type="file"
                              name={`educationsheet${item.id}`}
                              DocumentID={item.inputsEnabled.includes(0) ? `${item.sheets[Object.keys(item.sheets).length - 2].DocumentID}` : null}
                              documentNumber={item.inputsEnabled.includes(0) ? Object.keys(item.sheets).length - 2:null}
                              DocumentType="Employment"
                            />
                            {item.inputsEnabled.includes(0) ? <div className="">
                              <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{Object.keys(item.sheets)[Object.keys(item.sheets).length - 2]}</div></div>
                              <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >Error:<div style={{ color: "red", wordBreak: "break-word" }}>{JSON.parse(item.sheets["0"].ErrorMessage)}</div></div>
                            </div> : null}
                          </div>
                          <div>
                            {item.inputsEnabled.includes(1) ? Object.values(item.sheets).slice(0, -2).map((value, index) =>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <label style={{ fontSize: "13px" }} >
                                  Employment Evidence{" "}
                                  <label style={{ color: "red", fontSize: "13px" }}>
                                    *
                                  </label>
                                </label>
                                <input
                                  disabled={!item.inputsEnabled.includes(1)}
                                  onChange={onchangeElement1}
                                  accept="application/pdf"
                                  type="file"
                                  name={`educationtranscript${item.id}`}
                                  DocumentID={value.DocumentID}
                                  documentNumber={index}
                                  DocumentType="Employment"
                                />
                                <div className="">
                                  <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >FileName:<div style={{ color: "red", wordBreak: "break-word" }}>{value.DocumentName}</div></div>
                                  <div style={{ display: "flex", width: "298px", justifyContent: "space-between", textAlign: "end" }} >Error:<div style={{ color: "red", wordBreak: "break-word" }}>{JSON.parse(value.ErrorMessage)}</div></div>
                                </div>
                              </div>
                            ) : item.sheets === undefined ? <div style={{ display: "flex", flexDirection: "column" }}>
                              <label style={{ fontSize: "13px" }} >
                                Employment Evidence{" "}
                                <label style={{ color: "red", fontSize: "13px" }}>
                                  *
                                </label>
                              </label>
                              <input
                                disabled={true}
                                onChange={onchangeElement1}
                                accept="application/pdf"
                                type="file"
                                name={`educationtranscript${item.id}`}
                              />
                            </div> : null}
                          </div>
                          <div
                            onClick={() => removeEmploymentSheet(item.id)}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontWeight: "bold",
                              textAlign: "center",
                              marginTop: "5px",
                            }}
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              </div>:null
            }{/* <div
              onClick={removeEmploymentSheet}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#0971ce",
                width: "60px",
                height: "40px",
                // borderRadius: "10000px",
                marginTop: "30px",
                alignItems: "center",
                margin: "auto",
                color: "white",
                fontWeight: "bolder",
              }}
            >
              Add
            </div> */}
          </div>
          <button onClick={upload} className="FormSubmitBtn" type="button">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Reupload;
