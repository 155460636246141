// src/context/ErrorsContext.js

import React, { createContext, useState } from 'react';

// Create the Context
export const ErrorsContext = createContext();

// Create a Provider component
export const ErrorsProvider = ({ children }) => {
  // Initialize the state with an empty object or an initial JSON object
  const [Errors, setErrors] = useState({});

  return (
    <ErrorsContext.Provider value={{ Errors, setErrors }}>
      {children}
    </ErrorsContext.Provider>
  );
};
