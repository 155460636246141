import React, { useEffect, useState } from "react";
import "./../../css/formField.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const FormField = (props) => {
  const [emp_ref, setempref] = useState([
    { id: 1, sheet: {}, record: "" },
  ]);
  const [sheetData, setSheetData] = useState({});
  const [empData, setEmpData] = useState({});
  const [records, setRecords] = useState({});
  const { id } = useParams();
  const email = id
  useEffect(() => {
    axios.get(`https://api.xhorizons-dev.lexxtechnologies.com/getemps/${email}`).then((res) => {
      console.log(res.data);
      const evidences = res.data;
      setempref(evidences.map((evidence, index) => {
        return {
          id: index + 1,
          sheet: {
          },
          record: evidence.employmentdata.employment_record,
        }
      }));
      console.log(emp_ref);
    }).catch((err) => {
      console.log(err);
    });
  }, [])
  const navigate = useNavigate();
  const [data, setData] = useState({
    email_id: "",
    emp_ref: [],
    pay_evi: [],
    others: [],
    num_uploaded:{},
  });
  
  const addMoreEducationSheet = () => {
    if (emp_ref.length < 5) {
      const newId = emp_ref.length + 1;
      setempref([...emp_ref, { id: newId, sheet: {} }]);
    } else {
      // Optionally, you can show a message or handle this case
      window.alert("Only 5 employment references are accepted.");
    }
  };
  const removeEducationSheet = (id) => {
    if (emp_ref.length === 1) {
      alert("At least one Emp Ref. is mandatory.");
      return;
    }
    setempref(emp_ref.filter((sheet) => sheet.id !== id));
  };
  const onchangeElement = (e) => {
    const { name, value, files } = e.target;
    if (name === "email_id") {
      setData({ ...data, [name]: value });
    } else if (files && files[0]) {
      // Check file size
      if (name !== "emp_sheet" && files[0].size > 1e6) {
        alert(
          "File size is more than 1MB. Please upload a file with size less than 1MB."
        );
        e.target.value = ""; // Clear the file input
        return;
      }
      // Check file type
      const allowedTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];
      if (!allowedTypes.includes(files[0].type)) {
        alert("Please upload only PDF or Word files.");
        e.target.value = ""; // Clear the file input
        return;
      }
      setData({ ...data, [name]: files[0] });
    }
  };
  const onchangeElement1 = (e, record) => {
    console.log(e.target.getAttribute("documentType"));
    if (!e.target.files || !e.target.files[0]) {
      return; // No file selected, do nothing
    }
    const documentType = e.target.getAttribute("documentType");
    if (e.target.files[0].size > 1e6) {
      alert(
        "File size is more than 1MB. Please upload a file with size less than 1MB."
      );
      e.target.value = "";
      return false;
    }
  
    let name = e.target.name;
    let temp = sheetData;
    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      alert("Please upload only PDF or Word files.");
      e.target.value = ""; // Clear the file input
      return;
    }
  
    temp[name] = e.target.files;
  
    if (documentType === "pay_evi") {
      const currentFiles = data.pay_evi.filter((item) => item.record === record);
      const totalFiles = currentFiles.length + e.target.files.length;
  
      // Check if total number of Pay Evidence files is between 2 and 3
      if (totalFiles < 2 || totalFiles > 3) {
        alert("Please upload between 2 to 3 Pay Evidence documents.");
        e.target.value = ""; // Clear the file input
        return;
      }
  
      console.log("emp_evi");
      setData({
        ...data,
        pay_evi: [...data.pay_evi, ...e.target.files],
        num_uploaded: { ...data.num_uploaded, [record]: totalFiles },
      });
    }
  
    if (documentType === "emp_ref") {
      setData({ ...data, emp_ref: [...data.emp_ref, e.target.files[0]] });
    }
  
    if (documentType === "others") {
      setData({ ...data, others: [...data.others, ...e.target.files] });
    }
  
    setSheetData({ ...temp });
  };
  
  useEffect(() => {
    console.log(data);
    console.log(sheetData);
  }, [data, sheetData]);
  const checkMandatoryFields = () => {
    const mandatoryFields = ["email_id", "emp_ref", "pay_evi"];
    for (let field of mandatoryFields) {
      if (
        !data[field] ||
        (Array.isArray(data[field]) && data[field].length === 0)
      ) {
        alert(`Please fill all mandatory fields.`);
        return false;
      }
    }
    return true;
  };
  const upload = async () => {
    if (!checkMandatoryFields()) {
      return;
    }
    const formData = new FormData();
    formData.append("email_id", data.email_id);
    data.emp_ref.forEach((file) => formData.append("emp_ref", file));
    data.pay_evi.forEach((file) => formData.append("pay_evi", file));
    formData.append("num_uploaded", JSON.stringify(data.num_uploaded));
    data.others.forEach((file) => formData.append("others", file));
    const settings = {
      method: "POST",
      body: formData,
    };
    try {
      formData.forEach((value, key) => {
        console.log(key + " " + value);
      });
      const fetchResponse = await fetch(
        "https://api.xhorizons-dev.lexxtechnologies.com/uploademp",
        settings
      );
      const responseJson = await fetchResponse.json();
      if (fetchResponse.ok) {
        if (responseJson.error) {
          alert(responseJson.error); // Error from Python backend
        } else {
          alert("Files uploaded successfully!");
          navigate("/formsubmitted");
        }
      } else {
        alert(`Upload failed. HTTP Status: ${fetchResponse.status}`);
      }
    } catch (e) {
      console.log(e);
      alert(
        "An error occurred during upload. Please check the console for details."
      );
    }
  };
  return (
    <>
      <div className="form_body">
        <div className="h-context">
          <h3 className="pt-5 form_heading">Upload Documents</h3>
          <ul>
            <li style={{ color: "red" }}>
              The documents should be clear and colored.
            </li>
            <li style={{ color: "red" }}>
              Please note that fields marked with an asterisk (*) are mandatory.
            </li>
            <li style={{ color: "red" }}>
              Please upload files in PDF, PNG, or JPEG format only.
            </li>
            <li style={{ color: "red" }}>
              Please upload two Pay Evidence for each employment.
            </li>
          </ul>
        </div>
        <form id="form1" className="pb-10" encType="multipart/form-data">
          <div>
            <h3 className="heading-form personal_details mb-5">
              PERSONAL DETAILS
            </h3>
            <div className="grid-container three-by-two">
              <div className="first-name personal_details_inputfields">
                <label>
                  Email{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input onChange={onchangeElement} type="text" name="email_id" />
              </div>
              <div className="last-name personal_details_inputfields">
                {/* <label>
                  {" "}
                  Passport{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangeElement}
                  accept="application/pdf"
                  type="file"
                  name="passport"
                /> */}
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Additional Documents
                  {/* <label style={{ color: "red", fontSize: "13px" }}>*</label> */}
                </label>
                <input
                  multiple
                  onChange={onchangeElement1}
                  accept=".pdf, image/*"
                  type="file"
                  name="others"
                  documentType = "others"

                />
              </div>
              <div className="personal_details_inputfields"></div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                id="EducationDetail"
                className="filed-area"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "47px",
                  justifyContent: "center",
                  marginBottom: "10px",
                  width: "100%",
                  padding: "2%",
                }}
              >
                {emp_ref.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="emp_details_inputfields"
                    >
                      <div style={{ width: "600px" }}>{item.record} : - </div>
                      <div style={{display:"flex", width:"102%", gap:"20px", alignItems:"center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width:"100%" }}>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ fontSize: "13px" }}>
                              Employment Reference{" "}
                              <label style={{ color: "red", fontSize: "13px" }}>
                                *
                              </label>
                            </label>
                            <input
                              onChange={(e)=>{
                                onchangeElement1(e, index);
                                
                              }}
                              accept="application/pdf"
                              type="file"
                              name={`emp_ref${item.id}`}
                              documentType = "emp_ref"
                            />
                          </div>
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ fontSize: "13px" }}>
                              Pay Evidence{" "}
                              <label style={{ color: "red", fontSize: "13px" }}>
                                *
                              </label>
                            </label>
                            <input
                              multiple
                              onChange={(e)=>{
                                onchangeElement1(e, index);
                                
                              }}
                              accept="application/pdf"
                              type="file"
                              name={`emp_ref${item.id}`}
                              documentType = "pay_evi"
                            />
                          </div>
                        </div>
                        <div
                            onClick={() => removeEducationSheet(item.id)}
                            style={{
                              cursor: "pointer",
                              color: "red",
                              fontWeight: "bold",
                              textAlign: "center",
                              marginTop: "5px",
                            }}
                          >
                            Remove
                          </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              onClick={addMoreEducationSheet}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#0971CE",
                width: "60px",
                height: "40px",
                // borderRadius: "10000px",
                marginTop: "30px",
                alignItems: "center",
                margin: "auto",
                color: "white",
                fontWeight: "bolder",
              }}
            >
              Add
            </div>
          </div>
          <button onClick={upload} className="FormSubmitBtn" type="button">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};
export default FormField;
